<template>
  <Faq1
    id="faq"
    title="Frequently Asked Questions"
    subtitle="Some answers that may save you an email!"
    :faq="faq"
  />
</template>

<script>
import Faq1 from '@/components/ui/landing/faq/Faq1.vue'

export default {
  components: {
    Faq1
  },
  data() {
    return {
      faq: [{
        question: 'Is RestEaze FDA Approved?',
        answer: 'Tanzen Medical has received an FDA Breakthrough Device Designation for our leg movement monitor, RestEaze. We are still in the process of applying for FDA 510k approval.'
      }, {
        question: 'Are you open for grant collaboration?',
        answer: 'Yes, we are willing to colaborate on research grants in the area of sleep movement disorder.'
      }, {
        question: 'Is an Android phone required to use RestEaze?',
        answer: 'For now, Yes... We are in the process of developing an iOS app, as well as a PC version of the RestEaze application.'
      }, {
        question: 'How accurate are the Leg Movement and Arousal classification?',
        answer: 'Our classifier output is compared to the gold standard - PSG recordings-  marked by different sleep lab scorers (human and automated). We will release a report of our accuracies and studies in the near future.'
      }, {
        question: 'When will RestEaze become commercially available?',
        answer: 'We are in the process of kicking off larger scale manufacturing and expect RestEaze to be available for purchase in limited quantities by end of 2021.'
      }, {
        question: 'Are the analytics open-source?',
        answer: 'No our analytics, machine learners and training data are all proprietary.'
      }]
    }
  }
}
</script>
