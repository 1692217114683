<template>
  <v-main color="transparent">
    <Toolbar />
    <div>
      <Contact />
      <Faq />
    </div>
    <Footer />
  </v-main>
</template>

<script>

import Toolbar from '@/components/header/Toolbar.vue'
import Contact from '@/components/support/Contact.vue'
import Faq from '@/components/support/Faq.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  components: {
    Toolbar,
    Contact,
    Faq,
    Footer
  }
}
</script>
